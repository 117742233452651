import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { graphql } from 'gatsby'
import ContentHero from '../components/ContentHero'

const ApplicationProcess = props => {
  const { data } = props
  return (
    <Layout>
      <Hero
        title="Mitgestalter"
        subtitle="Werte zu schaffen ist unsere Leidenschaft"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="Bewerbungsprozess"
          subtitle="Für uns ist entscheidend, inwieweit du mit deiner Persönlichkeit, deiner Erfahrung, deinen methodischen und fachlichen Fähigkeiten und deinem Potenzial zu uns passt und inwieweit die colenio zu dir passt. An der Auswahlentscheidung wirken immer alle Teilnehmer der einzelnen Bewerbungsphasen mit.
          Nach jedem Schritt im Bewerbungsverfahren erhältst du ein ausführliches, persönliches Feedback von uns, und wir reden darüber, wie es danach weitergeht.
          Es findet in der Regel zuerst ein Telefoninterview statt. Im Anschluss findet ein Videointerview und zuletzt noch ein persönliches Vorstellungsgespräch statt. Bei Entwicklern wird statt dem klassischen Vorstellungsgespräch eine Coding Challenge durchgeführt.
          Bei jedem Gespräch sind je nach Stellenschwerpunkt entsprechende Kollegen aus den Teams dabei, ein Vertreter aus Human Resources und je nach Gespräch auch ein Geschäftsführer. Eine Entscheidung wird immer gemeinsam getroffen."
        />
      </div>
    </Layout>
  )
}

export default ApplicationProcess

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
